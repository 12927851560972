import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { CreateDatasetMenuComponent } from './create-dataset-menu/create-dataset-menu.component';

@NgModule({
  declarations: [CreateDatasetMenuComponent],
  imports: [CommonModule, NgbModule, TranslateModule.forRoot()],
  exports: [CreateDatasetMenuComponent],
})
export class DatasetMenuModule {}
