<div ngbDropdown class="d-inline-block v2-dropdown" placement="right-top">
  <div ngbDropdownToggle>
    <ng-content></ng-content>
  </div>

  <div ngbDropdownMenu>
    <a ngbDropdownItem href="/datasets/new-dataset/upload" [target]="blank ? '_blank' : ''">{{ 'Upload a File' | translate }}</a>
    <a ngbDropdownItem href="/datasets/new-dataset/db-connect" [target]="blank ? '_blank' : ''">{{ 'Connect to Database' | translate }}</a>
    <a ngbDropdownItem href="/datasets/new-dataset/aggregate" [target]="blank ? '_blank' : ''">{{ 'Aggregate' | translate }}</a>
    <a ngbDropdownItem href="/consent-broker/create" [target]="blank ? '_blank' : ''">{{ 'Create a Consent Campaign' | translate }}</a>
  </div>
</div>
